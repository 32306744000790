<script>
import visitorConfigs from "@/configs/visitorConfigs"
import { mapGetters } from "vuex"
import moment from "moment-timezone"

export default {
  name: "NavComponent",
  computed: {
    ...mapGetters({
      appPassesNotf: "studentAptPass/todaysAppSidebarCounter",
      user: "authentication/user",
      releases: "updates/releases",
      isUserUnavailable: "authentication/isUserUnavailable",
      unavailable: "dashboardTable/unavailable",
      isActiveTardyModule: "schools/isActiveTardyModule",
      tardyManagementOptions: "schools/tardyManagementOptions",
      isReportNew: "totalPassReports/isReportNew"
    }),
    isTardyAllowedForStudents() {
      return (
        this.isActiveTardyModule &&
        this.tardyManagementOptions?.["allow-students-see-level"]
      )
    },
    OutOfOfficeBadge() {
      return this.isUserUnavailable || this.unavailable ? "bg-green" : "d-none"
    },
    isReportNewBadge() {
      return this.isReportNew ? "bg-green" : "d-none"
    },
    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Digital ID",
              to: "/id-card",
              fontIcon: "fi flaticon-profile-line",
              requiredRoles: ["student"],
              _attrs: {
                "data-test-class": "nav-item"
              }
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Create Pass",
              to: "/passes/create",
              fontIcon: "fi flaticon-create-pass",
              requiredRoles: ["student"],
              _attrs: {
                "data-test-class": "nav-item"
              }
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Active Pass",
              to: "/passes/activepass",
              fontIcon: "fi flaticon-compass",
              requiredRoles: ["student"],
              _attrs: {
                "data-test-class": "nav-item"
              }
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Consequences Ladder",
              to: "/tardy/consequences-level",
              fontIcon: "fi flaticon-information",
              requiredRoles: ["student"],
              requiredModule: "Tardy Management",
              routeDependency: "routerDependencies/isStudentLadderVisible",
              _attrs: {
                "data-test-class": "nav-item"
              }
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Dashboard",
              _attrs: {
                "data-test-class": "nav-item"
              },
              to: "/dashboard",
              fontIcon: "ri-dashboard-line",
              requiredRoles: ["superadmin", "admin", "teacher", "staff"]
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "History",
              to: "/history",
              fontIcon: "ri-history-line",
              requiredRoles: ["superadmin", "admin", "teacher", "staff"],
              hidden: true,
              _attrs: {
                "data-test-class": "nav-item"
              }
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Teacher Pass",
              to: "/teacher-pass",
              fontIcon: "ri-door-open-line",
              requiredRoles: ["superadmin", "admin", "teacher", "staff"],
              _attrs: {
                "data-test-class": "nav-item"
              }
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Appointments",
              to: "/appointments",
              fontIcon: "ri-calendar-check-line",
              _attrs: {
                "data-test-id": "AppointmentPass",
                "data-test-class": "nav-item"
              },
              requiredRoles: [
                "superadmin",
                "admin",
                "teacher",
                "staff",
                "student"
              ],
              requiredModule: "Appointmentpass",
              badge:
                this.appPassesNotf &&
                this.appPassesNotf > 0 &&
                this.user.role.name == "student"
                  ? {
                      _class: "bg-red-gradient text-white",
                      color: "bg-red-gradient text-white",
                      text: this.appPassesNotf
                    }
                  : {}
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Kiosk",
              to: "/kiosk",
              _attrs: {
                "data-test-id": "Kiosk",
                "data-test-class": "nav-item"
              },
              fontIcon: "ri-device-line",
              requiredRoles: ["superadmin", "admin", "teacher", "staff"],
              requiredModule: "Kiosk"
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Out of Office",
              to: "/out-of-office",
              fontIcon: "ri-suitcase-3-line",
              requiredRoles: ["superadmin", "admin", "teacher", "staff"],
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-id": "out-of-office"
              },
              badge: {
                color: this.OutOfOfficeBadge,
                text: "ON"
              }
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: "Tardy Management",
              to: "/tardy-management/",
              fontIcon: "ri-alarm-line",
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              requiredRoles: ["superadmin", "admin", "teacher", "staff"],
              requiredModule: "Tardy Management",
              routeDependency: "routerDependencies/isVisibleTardyManagment",
              items: [
                {
                  component: "CNavItem",
                  name: "Tardy Reports",
                  to: "/tardy-management/reports",
                  fontIcon: "fi flaticon-information",
                  "data-test-class": "nav-item",
                  requiredRoles: ["superadmin", "admin", "teacher", "staff"]
                },
                {
                  component: "CNavItem",
                  name: "Tardy Detention & Appointment List",
                  to: "/tardy-management/detentions-appointments",
                  fontIcon: "fi flaticon-information",
                  "data-test-class": "nav-item",
                  requiredRoles: ["superadmin", "admin", "teacher", "staff"]
                }
              ]
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: visitorConfigs.pages.managementPage().name,
              to: visitorConfigs.pages.managementPage().path,
              fontIcon: "ri-user-line",
              requiredRoles: visitorConfigs.pages.managementPage().roles,
              requiredModule: visitorConfigs.pages.managementPage().module,
              routeDependency: visitorConfigs.pages.managementPage().dependency,
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              items: [
                {
                  component: "CNavItem",
                  name: visitorConfigs.pages.checkInLogsPage().name,
                  to: visitorConfigs.pages.checkInLogsPage().path,
                  "data-test-class": "nav-item",
                  requiredRoles: visitorConfigs.pages.checkInLogsPage().roles,
                  routeDependency:
                    visitorConfigs.pages.checkInLogsPage().dependency
                },
                {
                  component: "CNavItem",
                  name: visitorConfigs.pages.printerPage().name,
                  to: visitorConfigs.pages.printerPage().path,
                  "data-test-class": "nav-item",
                  requiredRoles: visitorConfigs.pages.printerPage().roles,
                  routeDependency: visitorConfigs.pages.printerPage().dependency
                }
              ]
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Updates",
              to: "/updates",
              fontIcon: "ri-radar-line",
              _attrs: {
                "data-test-class": "nav-item"
              },
              requiredRoles: [
                "superadmin",
                "admin",
                "teacher",
                "staff",
                "student"
              ],
              badge:
                this.newReleases.length > 0
                  ? {
                      color: "bg-gradient-blue",
                      text: "NEW"
                    }
                  : {}
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: "Training",
              to: "/training/",
              fontIcon: "ri-information-line",
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              requiredRoles: [
                "superadmin",
                "admin",
                "teacher",
                "staff",
                "student"
              ],
              items: [
                {
                  component: "CNavItem",
                  name: "Documents",
                  to: "/training/documents",
                  fontIcon: "fi flaticon-pencil",
                  _attrs: {
                    "data-test-class": "nav-item"
                  },
                  requiredRoles: [
                    "superadmin",
                    "admin",
                    "teacher",
                    "staff",
                    "student"
                  ]
                },
                {
                  name: "Video Tutorials",
                  component: "CNavItem",
                  to: "/training/videos",
                  fontIcon: "fi flaticon-video",
                  _attrs: {
                    "data-test-class": "nav-item"
                  },
                  requiredRoles: [
                    "superadmin",
                    "admin",
                    "teacher",
                    "staff",
                    "student"
                  ]
                }
              ]
            },
            {
              _name: "CSidebarNavTitle",
              component: "CNavTitle",
              _children: ["Control Panel"],
              name: "Control Panel",
              requiredRoles: ["superadmin", "admin"]
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: "Reports",
              to: "/reports",
              fontIcon: "ri-survey-line",
              requiredRoles: ["superadmin", "admin"],
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              items: [
                {
                  name: "Summary Reports",
                  component: "CNavItem",
                  to: "/reports/summary-reports",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Meet-up Report",
                  to: "/reports/meet-up-report",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Pass Totals",
                  to: "/reports/pass-totals",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  },
                  badge: {
                    color: this.isReportNewBadge,
                    text: "New"
                  }
                }
              ]
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: "Tardy Settings",
              to: "/tardy-settings",
              fontIcon: "ri-settings-6-line",
              requiredRoles: ["superadmin", "admin"],
              requiredModule: "Tardy Management",
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              items: [
                {
                  component: "CNavItem",
                  name: "Tardy Adults",
                  to: "/tardy-management/adults",
                  "data-test-class": "nav-item",
                  requiredRoles: ["superadmin", "admin"],
                  routeDependency: "routerDependencies/isVisibleTardyAdults"
                },
                {
                  component: "CNavItem",
                  name: "Tardy CSV Upload",
                  to: "/tardy-management/tardy-csv-upload",
                  "data-test-class": "nav-item",
                  requiredRoles: ["superadmin", "admin", "teacher", "staff"],
                  routeDependency: "routerDependencies/isVisibleTardyCSVUpload"
                },
                {
                  component: "CNavItem",
                  name: "Tardy Detention(s)",
                  to: "/tardy-settings/setup-detention",
                  requiredRoles: ["superadmin", "admin"],
                  "data-test-class": "nav-item"
                },
                {
                  component: "CNavItem",
                  name: "Detention Calendar",
                  to: "/tardy-management/detention-calendar",
                  fontIcon: "fi flaticon-information",
                  requiredRoles: ["superadmin", "admin"]
                },
                {
                  component: "CNavItem",
                  name: "Consequences Ladder",
                  to: "/tardy-settings/consequences",
                  requiredRoles: ["superadmin", "admin"],
                  "data-test-class": "nav-item"
                }
              ]
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: visitorConfigs.pages.settingsPage().name,
              to: visitorConfigs.pages.settingsPage().path,
              fontIcon: "ri-user-line",
              requiredRoles: visitorConfigs.pages.settingsPage().roles,
              requiredModule: visitorConfigs.pages.settingsPage().module,
              routeDependency: visitorConfigs.pages.settingsPage().dependency,
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              items: [
                {
                  component: "CNavItem",
                  name: visitorConfigs.pages.adultsPage().name,
                  to: visitorConfigs.pages.adultsPage().path,
                  "data-test-class": "nav-item",
                  requiredRoles: visitorConfigs.pages.adultsPage().roles,
                  routeDependency: visitorConfigs.pages.adultsPage().dependency
                },
                {
                  component: "CNavItem",
                  name: visitorConfigs.pages.guardianStudentMatrixPage().name,
                  to: visitorConfigs.pages.guardianStudentMatrixPage().path,
                  "data-test-class": "nav-item",
                  requiredRoles:
                    visitorConfigs.pages.guardianStudentMatrixPage().roles,
                  routeDependency:
                    visitorConfigs.pages.guardianStudentMatrixPage().dependency
                },
                {
                  component: "CNavItem",
                  name: visitorConfigs.pages.generalPage().name,
                  to: visitorConfigs.pages.generalPage().path,
                  "data-test-class": "nav-item",
                  requiredRoles: visitorConfigs.pages.generalPage().roles,
                  routeDependency: visitorConfigs.pages.generalPage().dependency
                },
                {
                  component: "CNavItem",
                  name: visitorConfigs.pages.kioskPage().name,
                  to: visitorConfigs.pages.kioskPage().path,
                  "data-test-class": "nav-item",
                  requiredRoles: visitorConfigs.pages.kioskPage().roles,
                  routeDependency: visitorConfigs.pages.kioskPage().dependency
                },
                {
                  component: "CNavItem",
                  name: visitorConfigs.pages.listPage().name,
                  to: visitorConfigs.pages.listPage().path,
                  "data-test-class": "nav-item",
                  requiredRoles: visitorConfigs.pages.listPage().roles,
                  routeDependency: visitorConfigs.pages.listPage().dependency
                },
                {
                  component: "CNavItem",
                  name: visitorConfigs.pages.securityPage().name,
                  to: visitorConfigs.pages.securityPage().path,
                  "data-test-class": "nav-item",
                  requiredRoles: visitorConfigs.pages.securityPage().roles,
                  routeDependency:
                    visitorConfigs.pages.securityPage().dependency
                }
              ]
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: "Limits",
              to: "/limits",
              fontIcon: "ri-dashboard-2-line",
              requiredRoles: ["superadmin", "admin"],
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              items: [
                {
                  component: "CNavItem",
                  name: "Limit Student Pass",
                  to: "/limits/limit-student-pass",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Limit Location Max Cap",
                  to: "/limits/limit-location-max-cap",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Limit Active Pass",
                  to: "/limits/limit-active-pass",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Limit Location Availability",
                  to: "/limits/limit-location-availability",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Contact Control (A/B Polarity)",
                  to: "/limits/ab-polarity",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Pass Blocking",
                  to: "/limits/pass-blocking",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                }
              ]
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: "Users",
              to: "/users/main-users",
              fontIcon: "ri-user-settings-line",
              requiredRoles: ["superadmin", "admin"],
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              items: [
                {
                  component: "CNavItem",
                  name: "Main Users",
                  to: "/users/main-users",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Substitute Users",
                  to: "/users/substitute-users",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Archived Users",
                  to: "/users/archived-users",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                }
              ]
            },
            {
              _name: "CSidebarNavDropdown",
              component: "CNavGroup",
              name: "System Settings",
              to: "/system-settings",
              fontIcon: "ri-sound-module-line",
              requiredRoles: ["superadmin", "admin"],
              _attrs: {
                "data-test-class": "nav-item",
                "data-test-type": "nav-dropdown"
              },
              items: [
                {
                  component: "CNavItem",
                  name: "Auto Pass",
                  to: "/system-settings/auto-pass",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Pass Times",
                  to: "/system-settings/pass-times",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Periods",
                  to: "/system-settings/periods",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Locations (rooms)",
                  to: "/system-settings/rooms",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                },
                {
                  component: "CNavItem",
                  name: "Pass Transparency",
                  to: "/system-settings/pass-transparency",
                  requiredRoles: ["superadmin", "admin"],
                  _attrs: {
                    "data-test-class": "nav-item"
                  }
                }
              ]
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Modules",
              to: "/modules",
              fontIcon: "ri-list-settings-line",
              requiredRoles: ["superadmin", "admin"],
              _attrs: {
                "data-test-class": "nav-item"
              }
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "File Uploads",
              to: "/file-uploads",
              fontIcon: "ri-upload-2-line",
              requiredRoles: ["superadmin", "admin"],
              _attrs: {
                "data-test-class": "nav-item"
              }
            },
            {
              _name: "CSidebarNavItem",
              component: "CNavItem",
              name: "Help Desk",
              to: "/help-desk",
              fontIcon: "ri-lightbulb-line",
              requiredRoles: ["superadmin", "admin"],
              _attrs: {
                "data-test-class": "nav-item"
              }
            }
          ]
        }
      ]
    },
    nextSevenDays() {
      const today = moment().format("MM/DD/YYYY")
      const nextSevenDays = moment().add(7, "days").format("MM/DD/YYYY")
      return today.toString() + "," + nextSevenDays.toString()
    },
    isActiveAppoinmentModule() {
      return this.$store.getters["schools/isActiveAppoinmentModule"]
    }
  },
  methods: {
    getLadders() {
      if (this.user.role.name == "student" && this.isTardyAllowedForStudents) {
        this.$store.dispatch("consequencesLadder/getAllLadders")
      }
    },
    getStudentAptPasses() {
      if (this.user.role.name == "student") {
        this.$store.dispatch("studentAptPass/getTodaysAppointmentPasses")
      }
    }
  },
  created() {
    this.getLadders()
    this.getStudentAptPasses()
  },
  watch: {
    isTardyAllowedForStudents() {
      this.getLadders()
    }
  }
}
</script>
